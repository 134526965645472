<template>
<section id="form-section">
    <div class="d-flex">
        <label for="name" class="col-sm-3 col-form-label">Naam</label>
        <input v-model="localPensionCompany.name" class="form-control form-control-sm" id="name" />
    </div>
    <div>
        <label for="is_provider" class="col-sm-3 col-form-label">Type</label>
        <div class="pt-1">
            <div v-for="companyType in companyTypes" v-bind:key="companyType.id" class="d-flex">
                <input type="checkbox" name="companyType" :value="companyType.id" :id="'company_type_checkbox_' + companyType.id" class="form-check" v-model="localPensionCompany.company_types" :true-value="1" :false-value="0"/>
                <label :for="'company_type_checkbox_' + companyType.id" class="ms-2 d-inline"> {{companyType.name}}</label>
            </div>
        </div>
    </div>
    <div>
        <label for="code" class="col-sm-3 col-form-label">Code</label>
        <input v-model="localPensionCompany.code" class="form-control form-control-sm" id="code" />
    </div>
    <div>
        <label for="is_provider" class="col-sm-3 col-form-label d-inline-block">Aanbieder</label>
        <input class="form-check d-inline" type="checkbox" id="is_provider" v-model="localPensionCompany.is_provider" :true-value="1" :false-value="0">
    </div>
    <div>
        <label for="logo" class="col-sm-3 col-form-label">Logo</label>
        <div class="pt-1">
            <img v-if="localPensionCompany.LogoSVG" :src="localPensionCompany.LogoSVG" style="max-width: 300px">
            <input class="" type="file" id="logo" ref="file" @change="handleFileChange">
        </div>
    </div>
        <br>
    <div>
        <label for="benefit_requestaddress" class="col-sm-3 col-form-label">Uitkeringsverzoek adres</label>
        <textarea class="form-control" id="benefit_requestaddress" v-model="localPensionCompany.benefit_requestaddress"></textarea>
    </div>
    <div>
        <label for="benefit_request_email" class="col-sm-3 col-form-label">Uitkeringsverzoek e-mailadres</label>
        <div>
            <div v-for="(address, index) in localPensionCompany.benefit_emails" :key="address.uuid" class="d-flex gap-2 mb-1">
                <input v-model="address.email" type="email" class="form-control form-control-sm"/>
                <button v-if="index == 0" type="button" class="btn btn-sm btn-success" @click="addEmail(localPensionCompany.benefit_emails)" ><i class="fal fa-add"></i></button>
                <button v-else type="button" class="btn btn-sm btn-danger" @click="removeEmail(localPensionCompany.benefit_emails, index)"><i class="fal fa-trash-alt"></i></button>
            </div>
        </div>
    </div>
    <div>
        <label for="settlement_requestaddress" class="col-sm-3 col-form-label">Aanvraag adres</label>
        <textarea class="form-control" id="settlement_requestaddress" v-model="localPensionCompany.request_address"></textarea>
    </div>
    
    <div>
        <label for="request_mail" class="col-sm-3 col-form-label">Aanvraag e-mailadres</label>
        <div>
            <div v-for="(address, index) in localPensionCompany.request_emails" :key="address.uuid" class="d-flex gap-2 mb-1">
                <input v-model="address.email" type="email" class="form-control form-control-sm"/>
                <button v-if="index == 0" type="button" class="btn btn-sm btn-success" @click="addEmail(localPensionCompany.request_emails)"><i class="fal fa-add"></i></button>
                <button v-else type="button" class="btn btn-sm btn-danger" @click="removeEmail(localPensionCompany.request_emails, index)"><i class="fal fa-trash-alt"></i></button>
            </div>
        </div>
    </div>
    <div>
        <label for="receipt_iban" class="col-sm-3 col-form-label">Ontvangsten IBAN</label>
        <input id="receipt_iban" v-model="localPensionCompany.receipt_iban" class="form-control form-control-sm"/>
    </div>
    <div>
        <label for="receipt_reference" class="col-sm-3 col-form-label">Ontvangsten kenmerk</label>
        <input id="receipt_reference" v-model="localPensionCompany.receipt_reference" class="form-control form-control-sm"/>
    </div>
    <div>
        <label for="request_cd" class="col-sm-3 col-form-label d-inline-block">Compleet document bij aanvraag</label>
        <input class="form-check d-inline" type="checkbox" id="request_cd" v-model="localPensionCompany.request_complete_document" :true-value="1" :false-value="0">
    </div>
</section>
</template>

<script>
    export default {
        name: 'localPensionCompanyForm',
        props: ['itemData', 'companyTypes'],
        data() {
            return {
                localPensionCompany: { benefit_emails: [], request_emails: [] },
                submitted: false,
                loading: false,
                invalid: {}
            }
        },
        watch: {
            itemData: function (val) {
                this.localPensionCompany = val    
            },
        },
        methods: {
            validate(){
                this.invalid = {};
                let valid = true;

                // let required = document.querySelectorAll('[required]');
                this.localPensionCompany.benefit_request_email = this.localPensionCompany.benefit_emails.map((e) => e.email).join(',');
                this.localPensionCompany.request_mail = this.localPensionCompany.request_emails.map((e) => e.email).join(',');
                return valid;
            },
            getData() {
                return this.localPensionCompany;
            },
            setData(data) {
                this.localPensionCompany = data;
                this.localPensionCompany.benefit_emails = data.benefit_request_email ? data.benefit_request_email.split(',').map((e) => ({'uuid': Math.random(), 'email': e})) : [{'uuid': 1, 'email': ''}];
                this.localPensionCompany.request_emails = data.request_mail ? data.request_mail.split(',').map((e) => ({'uuid': Math.random(), 'email': e})) : [{'uuid': 1, 'email': ''}];
            },
            handleFileChange(evt) {
                var files = evt.target.files || evt.dataTransfer.files;
                
                if (!files.length) {
                    return;
                }
                
                var file = files[0];    
                var reader  = new FileReader();

                reader.onload = (e) => {
                    const imgBase64Path = e.target.result;
                    this.localPensionCompany.logo = imgBase64Path
                }

                if (file) {
                    reader.readAsDataURL(file)
                }
            },
            reset() {
                this.localPensionCompany = {
                    'code': '',
                    'email': '',
                    'name': '',
                    'is_provider': false,
                    'logo': null,
                    'benefit_requestaddress': null,
                    'benefit_request_email': null,
                    'request_mail': null,
                    'request_address': null,
                    'request_complete_document': 0,
                    'company_types': [],
                    'benefit_emails': [{'uuid': 1, 'email': ''}],
                    'request_emails': [{'uuid': 1, 'email': ''}]
                }
                
                this.loading = false
                this.invalid = {}
            },
            addEmail(list){
                list.push({'uuid': Math.random(), 'email': ''})
            },
            removeEmail(list, index){
                list.splice(index, 1)
            }

        },
        mounted() {
            this.reset()            
        }
    }
</script>
<style scoped>
    #form-section > div{
        display: flex;
    }
    #form-section > div > label{
        padding-top: .3rem !important
    }
    #form-section > div:not(:first-child){
        margin-top: 1rem;
    }
    #form-section > div > *:first-child{
        flex: 0 0 auto;
        width: 33%;
    }
    #form-section > div > *:last-child:not([type="checkbox"]){
        flex: 0 0 auto;
        width: 66%;
    }
    .form-control:not(textarea){
        height: 31px !important;
    }
    input.d-inline{
        vertical-align: baseline;
        margin-top: .25rem
    }
</style>