<template>
    <div class="col" v-if="localdossier">
        <div class="bg-white p-4">
            <div class="row" v-show="isNotPension">
                <div class="col-3">Lijfrente type:</div>
                <div class="col-3">
                    <select class="form-select form-select-sm" v-model="localdossier.lijfrente_type">
                        <option value="bancair">Bancair</option>
                        <option value="verzekering">Verzekering</option>
                    </select>
                </div>
            </div>
            <br/>
            <table class="quoteTable">
                <thead>
                    <tr>
                        <th class="ps-2 small"></th>
                        <th class="ps-2 small"></th>
                        <th>Maatschappij</th>
                        <th>Uitkering</th>
                        <th>Aangepast</th>
                        <th>Offerte</th>
                    </tr>
                </thead>
                <tbody v-if="localdossier.quotes[0] && localdossier.quotes[0].id">
                    <template v-for="(quote, index) in filteredQuotes" :key="index">
                        <tr class="quoteRow">
                            <td class="ps-2 small">
                                <input type="checkbox" class="form-check-input" v-model="quote.active" :true-value="1" :false-value="0"/>
                            </td>
                            <td class="small">
                                <i class="d-inline fa-solid fa-badge-check ms-2" v-if="selectedQuote && quote.id === selectedQuote.id"></i>
                            </td>
                            <td>
                                <select v-if="!quote.id" class="form-select" v-model="quote.provider_id">
                                    <option value="">Selecteer...</option>
                                    <template v-for="company in companies" v-bind:key="company.id">
                                        <option v-if="isNotAlreadyInQuote(company)" :value="company.id">{{company.name}}</option>
                                    </template>
                                </select>
                                <span v-else>
                                    <img v-if="quote.providing_company && quote.providing_company.LogoSVG && quote.providing_company.code != 'Overig'"
                                        class="companyLogo" :src="quote.providing_company.LogoSVG" />
                                    <div v-else class="companyLogo">
                                        {{quote.provider}}
                                    </div>
                                </span>
                            </td>
                            <td>
                                <span v-if="quote.id">
                                    <span :class="setStrikeThrough(quote.id)"><span v-if="quote.payment_low" style="color:#74787c;display-block:inline;bottom: 2px;position: relative;">Hoog</span> {{formatCurrency(quote.payment)}}</span>
                                    <span v-if="quote.payment_low" :class="setStrikeThrough(quote.id, true)"> <br><span style="color:#74787c;display-block:inline;bottom: 2px;position: relative;">Laag</span> {{formatCurrency(quote.payment_low)}} </span>
                                </span>
                            </td>
                            <td v-if="quote.id" class="pe-2">
                                <div class="input-group">
                                    <span class="input-group-text my-1">€</span>
                                    <input class="form-control my-1 number" :id="'so-'+index" v-model="quote.payment_special_offer" v-on:keyup="onKeyUp(quote, 'payment_special_offer')" @change="setFormattedNumber(quote, 'payment_special_offer')" >
                                </div>
                                <div v-if="quote.payment_low">
                                    <div class="input-group">
                                        <span class="input-group-text my-1">€</span>
                                        <input class="form-control my-1 number" :id="'lso-'+index" v-model="quote.payment_low_special_offer" v-on:keyup="onKeyUp(quote, 'payment_low_special_offer')" @change="setFormattedNumber(quote, 'payment_low_special_offer')">
                                    </div>
                                </div>
                            
                            </td> 
                            <td v-else class="pe-2">
                                <div class="input-group">
                                    <span class="input-group-text my-1">€</span>
                                    <input type="text" class="form-control my-1 number" v-model="quote.payment" v-on:keyup="onKeyUp(quote, 'payment')" @change="setFormattedNumber(quote, 'payment')"/>
                                </div>
                                <div v-if="localdossier.quotes[0].payment_low">
                                    <div class="input-group">
                                        <span class="input-group-text my-1">€</span>
                                        <input class="form-control my-1 number" type="text" v-model="quote.payment_low"  v-on:keyup="onKeyUp(quote, 'payment_low')" @change="setFormattedNumber(quote, 'payment_low')"/>
                                    </div>
                            </div>
                            </td>
                            <td>
                                <!--<a href="" v-if="quote && quote.company_quote_file_name"
                                    @click.prevent="downloadFile(quote.id, quote.company_quote_file_name)">{{quote.company_quote_file_name}}</a>
                                <input type="file" @change="uploadFile($event, quote.id, index)" ref="file" />-->
                                <a href="#" data-bs-toggle="modal" data-bs-target="#maatschappijOfferteModal" @click="setQuote(quote, index)">

                                    <template v-if="quote && quote.company_quote_file_name">
                                        {{quote.company_quote_file_name}}
                                    </template>
                                    <template v-else>
                                        Offerte uploaden...
                                    </template>

                                </a>
                            </td>
                        </tr>
                            
                    </template>
                </tbody>
            </table>
            <div class="d-flex mt-2">
            <div class="btn btn-primary" @click="addQuote" v-if="localdossier.quotes.length < companies.length">Maatschappij toevoegen</div>
            <button class="btn btn-primary" disabled v-else>Maatschappij toevoegen</button>
            </div>
            <div class="d-flex justify-content-end">
                <div class="btn btn-success" @click="overwriteQuotePayments">Opslaan</div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="maatschappijOfferteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-l">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Maatschappijofferte</h5>
                
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <h6>
                    Dossier #{{this.dossier.dossier_number}} - {{this.dossier.contact.addressed_name}}
                </h6>

                <template v-if="this.quote">

                    <template v-if="this.quote.company_quote_file_name">
                        <strong>Huidige maatschappijofferte:</strong><br>
                        <div class='row'>
                            <div class='col-6'>
                                <a href="" @click.prevent="downloadFile(this.quote.id, this.quote.company_quote_file_name)">{{this.quote.company_quote_file_name}}</a>
                            </div>
                            <div class='col-3'>
                                verloopt op: {{formatDate(this.quote.company_quote_expiration_date, "longDate")}}
                            </div>
                            <div class='col-3 text-end'>
                                <a href="" class="text-danger" @click.prevent="deleteQuoteFile()">verwijder</a>
                            </div>
                        </div>
                        <br>
                
                    </template>

                    <br>
                    <h6>Upload nieuwe maatschappijofferte:</h6>
                    <UploadModule
                        :dossier="dossier" 
                        :type="'company_quote'"
                        ref="company_quote"
                        maxfiles=1
                    />
                    <div class="invalid-feedback">
                    </div>
                    
                    <div class="row">
                        <label class='col-3 col-form-label' for="company_quote_expiration_date"> Verloopdatum </label>
                        <div class='col-9'>
                            <input class="form-control" id="company_quote_expiration_date" type="date" v-model="this.quote.new_company_quote_expiration_date">
                        </div>
                    </div>
                    
                </template>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id="closemodal">Sluiten</button>
                <button type="button" class="btn btn-primary" @click.prevent="uploadQuoteFile(this.quote)" :disabled="!this.quote.new_company_quote_expiration_date || this.$refs.company_quote.files.length == 0">Opslaan</button>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
    import dossierservice from '@/services/admin/DossierService'
    import pensionCompanyService from '@/services/admin/PensionCompanyService'
    import UploadModule from '@/components/dossier/UploadModule'

    export default {
        name: 'Quotes',
        components: {
            UploadModule
        },
        props: {
            dossier: {}
        },
        data() {
            return {
                companies: [],
                localdossier: null,
                prevDossier: null,
                quote: {
                    "id": null
                }
            }
        },
        computed: {
            selectedQuote() {
                return this.dossier.quotes.filter((q) => {
                    if (q.selected) {
                        return true
                    }
                })[0]
            },
            isNotPension(){
                return this.dossier.pension_fund_history[0] && this.dossier.pension_fund_history[0].origin != "Pensioen";
            },
            filteredQuotes(){
                return (this.localdossier.lijfrente_type && this.isNotPension) ? this.localdossier.quotes.filter(q => (q.lijfrente_type == this.localdossier.lijfrente_type || !q.lijfrente_type)) : this.localdossier.quotes;
            }
        },
        methods: {
            overwriteQuotePayments(showNotification = true) {
                //this.changeSeparators();
                const quotesAsString = JSON.stringify(this.localdossier.quotes);
                if(quotesAsString === JSON.stringify(this.prevDossier.quotes)){
                    if (showNotification) {
                        this.$toast.success('Uitkering is opgeslagen (geen wijzigingen)');
                    }
                    this.$parent.$parent.next(2);
                    return;
                }
                    
                dossierservice.overwriteQuotePayments(this.localdossier.quotes, this.localdossier.lijfrente_type).then(response => {
                    this.localdossier.quotes = this.$parent.$parent.sortQuotes(response.data);
                    if(this.$parent.dossier != undefined)
                        this.$parent.dossier.quotes = this.localdossier.quotes
                        if (showNotification) {
                            this.$toast.success('Uitkering is opgeslagen')
                        }
                        this.addSeparators();
                        this.prevDossier = JSON.parse(JSON.stringify(this.localdossier));
                        this.$parent.$parent.next(2)
                }).catch(error => {
                    if (showNotification) {
                        this.$toast.error('Kan uitkering niet opslaan')
                    }
                })
            },
            setQuote(quote, index) {
                this.overwriteQuotePayments(false);
                this.quote = quote
                //Set date to 2 weeks in the future
                this.quote.new_company_quote_expiration_date = new Date(new Date(new Date().getTime() + 1209600000)).toISOString().substring(0, 10)
                this.quote.index = index

                this.$refs.company_quote.files = []
            },
            addQuote() {
                this.$parent.$parent.dossier.quotes.push({
                    active: true,
                    payment: '',
                    payment_low: null,
                    payment_special_offer: null,
                    payment_low_special_offer: null,
                    provider: '',
                    dossier_id: this.dossier.id,
                    lijfrente_type: this.localdossier.lijfrente_type,
                    lijfrente_percentage: null,
                    selected: false
                })
            },
            getCompanies() {
                pensionCompanyService.index().then(response => {
                    this.companies = response.data
                }).catch(error => {
                    console.log('error', error) // create notification-toaster for user error
                })
            },

            uploadQuoteFile() {
                const file = this.$refs.company_quote.files[0];
                if(!file){
                    this.$toast.error("Er is geen bestand geselecteerd");
                    return;
                }
                let formData = new FormData();
                formData.append('file', file)
                formData.append('company_quote_expiration_date', this.quote.new_company_quote_expiration_date)
                dossierservice.uploadQuoteFile(formData, this.quote.id).then(response => {
                    this.localdossier.quotes[this.localdossier.quotes.findIndex(x => x.id === this.quote.id)] = response.data
                    document.getElementById("closemodal").click();
                }).catch(error => {
                    let message = 'Fout bij het uploaden';
                    if(error.response && error.response.data && error.response.data.message){
                        message = 'Fout bij het uploaden: ' + error.response.data.message;
                    }
                    this.$toast.error(message)
                    console.log(error)
                })
            },
            downloadFile(id, fileName) {
                dossierservice.downloadQuoteFile(id).then(response => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                }).catch(error => {
                    this.$toast.error('Failed to download file')
                })
            },
            deleteQuoteFile() {
                
                dossierservice.deleteQuoteFile(this.quote.id).then(response => {
                    this.quote.company_quote_file_name = null
                    this.localdossier.quotes[this.localdossier.quotes.findIndex(x => x.id === this.quote.id)].company_quote_file_name = null
                }).catch(error => {
                    this.$toast.error('Fout bij het verwijderen van de offerte')
                })
            },
            setStrikeThrough(id, isLow = false) {
                let item = this.prevDossier.quotes.find(x => x.id == id);
                if(!item) return '';
                let condition = isLow ? (item.payment_low_special_offer && item.payment_low_special_offer != null)
                                      : (item.payment_special_offer && item.payment_special_offer != null);
                return condition ? 'text-decoration-line-through' : '';
            },
            isNotAlreadyInQuote(item){
                let q = this.localdossier.quotes;
                let qlength = q.length;
                let foundDupe = false;
                let regex;
                for(let i = 0; i < qlength; i++){
                    regex = new RegExp(q[i].provider);
                    if(q[i].provider && regex.test(item.name)){
                        foundDupe = true;
                        break;
                    }
                }
                return !foundDupe;
            },
            addSeparators(){
                this.localdossier.quotes.forEach(item => {
                    if(item.payment_low_special_offer && item.payment_low_special_offer != null){
                        item.payment_low_special_offer = new Intl.NumberFormat('en', {useGrouping: false, minimumFractionDigits: 2, maximumFractionDigits: 2}).format(item.payment_low_special_offer);
                    }
                    if(item.payment_special_offer && item.payment_special_offer != null){
                        item.payment_special_offer = new Intl.NumberFormat('en', {useGrouping: false, minimumFractionDigits: 2, maximumFractionDigits: 2}).format(item.payment_special_offer);
                    }
                });
            },
            changeSeparators(){
                this.localdossier.quotes.forEach(item => {
                    if(item.payment_low_special_offer && item.payment_low_special_offer != null){
                        item.payment_low_special_offer = String(item.payment_low_special_offer).replace('.', '');
                    }
                    if(item.payment_special_offer && item.payment_special_offer != null){
                        item.payment_special_offer = String(item.payment_special_offer).replace('.', '');
                    }
                });
            },
            onKeyUp(item, field){
                if(item[field] != null){
                    item[field] = item[field].replace(/[^0-9,.]/g, '').replace(/[,]/g, '.');
                }
            },
            setFormattedNumber(item, field){
                if(!item[field]){
                    if(field == 'payment_special_offer') item[field] = null;
                    if(field == 'payment_low_special_offer') item[field] = null;
                    return;
                }
                item[field] = new Intl.NumberFormat('en', {useGrouping: false, minimumFractionDigits: 2, maximumFractionDigits: 2}).format(item[field]);
                console.log(item[field].replace('.', ','));
            },
            setTitle(gender){
                return gender == 'male' ? 'Dhr. ' : 'Mevr. ';
            }
            
        },
        mounted() {
            this.getCompanies()
            this.localdossier = this.dossier
            this.addSeparators();
            this.prevDossier = JSON.parse(JSON.stringify(this.dossier));
        }
    }
</script>
<style scoped>
    th.small, td.small{
        width: 4% !important
    }
    .selected-provider {
        background-color: rgb(148, 204, 148);
    }

    .quoteTable {
        border-collapse: collapse;
    }

    .quoteTable .quoteRow:first-child {
        border-top: 2px solid rgb(72, 100, 72);
    }

    .quoteTable .quoteRow:not(:first-child) {
        border-top: 1px solid rgb(220, 220, 220);
        border-bottom: 1px solid rgb(220, 220, 220);
        border-collapse: collapse;
    }

    .quoteTable .quoteRowLast {
        border-bottom: 2px solid rgb(72, 100, 72);
    }
    /* Remove spinners */
    input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    input[type=number] {
        -moz-appearance: textfield;
        appearance: textfield;
    }
    .fa-badge-check{
        color: #6dd400;
        font-size: 28px;
    }
</style>
<style>
    .companyLogo {
        display: inline;
        width: 80px;
    }
    .companyLogo>svg {
        height: 50px;
    }
</style>